import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import {
  FooterContainer,
  FooterLogo,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinksWrapper2,
  FooterLinkItems,
  FooterLinkItems2,
  FooterLinkItems3,
  FooterLinkItems4,
  FooterLinkItems5,
  FooterLinkTitle,
  FooterLink,
  FooterLink2,
  FooterLine,
  LastLine,
  SocialIcons,
} from "./FooterElements";

const linkStyle = {
  textDecoration: "none",
};

const socialIcons = {
  color: "#000000",
  fontSize: "20px",
};


function Footer() {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>
                <FooterLogo to="/" onClick={toggleHome}>
                  <img src={"../../images/deempay-logo.svg"} alt="logo" />
                </FooterLogo>
              </FooterLinkTitle>
              <FooterLink2 style={linkStyle} >294 Herbert Macaulay</FooterLink2>
              <FooterLink2 style={linkStyle}>Way, Yaba 101212</FooterLink2>
              <FooterLink2 style={linkStyle}>Lagos, Nigeria</FooterLink2>
              <br />
              <FooterLink2 style={linkStyle} >hello@deempay.com</FooterLink2>
              <FooterLink2 style={linkStyle} >+234 8179470378</FooterLink2>
            </FooterLinkItems>
            <FooterLinkItems2>
              <FooterLinkTitle>Our Solutions</FooterLinkTitle>
              <FooterLink to="/deempay-app" style={linkStyle} >DeemPay App</FooterLink>
              <FooterLink to="/deempay-dashboard" style={linkStyle} >DeemPay Dashboard</FooterLink>
            </FooterLinkItems2>
            <FooterLinkItems3>
              <FooterLinkTitle>Company</FooterLinkTitle>
              <FooterLink to="/about" style={linkStyle} >About</FooterLink>
              {/* <FooterLink to="/security" style={linkStyle} >Security</FooterLink> */}
              <FooterLink to="/careers" style={linkStyle} >Careers</FooterLink>
            </FooterLinkItems3>
          </FooterLinksWrapper>
          <FooterLinksWrapper2>
            <FooterLinkItems4>
              <FooterLinkTitle>Help</FooterLinkTitle>
              <FooterLink
                target="_blank"
                rel="noreferrer"
                to={"//www.youtube.com/channel/UCR_nKmQ37Yp49aqyyItxW6A"}
                style={linkStyle} 
              >
                Video Tutorial
              </FooterLink>
              <FooterLink to="/faq" style={linkStyle} >FAQ</FooterLink>
              <FooterLink to="/supportcenter" style={linkStyle} >Support Center</FooterLink>
              <FooterLink to="/blog" style={linkStyle} >Blog</FooterLink>
            </FooterLinkItems4>
            <FooterLinkItems5>
              <FooterLinkTitle>Legal</FooterLinkTitle>
              <FooterLink to="/privacy" style={linkStyle} >Privacy Policy</FooterLink>
              <FooterLink to="/termsofservice" style={linkStyle} >Terms of Service</FooterLink>
              <SocialIcons>
              <Link
                style={socialIcons}
                to={
                  "//www.linkedin.com/company/deempay/about/?viewAsMember=true"
                }
                target="_blank"
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </Link>
              <Link
                style={socialIcons}
                to={"//twitter.com/deempay"}
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </Link>

              <Link
                style={socialIcons}
                to={"//www.instagram.com/deempay_ng/"}
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </Link>
              <Link
                style={socialIcons}
                to={"//www.facebook.com/DeemPay-120820392659915/"}
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </Link>
            </SocialIcons>
            </FooterLinkItems5>
          </FooterLinksWrapper2>
        </FooterLinksContainer>
        
        <FooterLine />

        <LastLine>
          DeemPay Technology Limited ("DeemPay") is a Financial Technology Company duly registered with
          the Corporate Affairs Commission of Nigeria (RC: 1770757), with our office at 294 Herbert Macaulay Way, Yaba, Lagos.
        </LastLine>
        <LastLine>
          We help businesses accept payments via QR code and virtual bank transfer, we also customize solutions to meet different merchants needs. The Deempay QR code
          are generated by Nigeria Interbank-settlment Scheme (NIBSS) while Wema Bank generates the virtual bank account, and any mobile bank app in Nigeria can 
          scan and equally transfer and make payments on it. 
        </LastLine>
        <LastLine>
        © {new Date().getFullYear()} DeemPay Technology Limited.
        </LastLine>
      </FooterWrap>
    </FooterContainer>
  );
}

export default Footer;
