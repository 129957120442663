import styled from "styled-components";

export const Spin = styled.div`
     display: flex;
     align-items: center;
     justify-content: center;
`;

export const Img = styled.img`
     width: auto;
     height: auto;
     max-width: 100%;
     max-height: 100%;
     left: 50%;
     top: 50%;
     margin: 350px auto;    
     @media screen and (max-width: 480px) {
        margin: 200px auto;    
    
  }
`;