import React, { useState, useEffect } from "react";
import { Container } from "../../globalStyles";
import {
  InfoContainer,
  Head,
  SubHead,
  Title,
  // LineWrap,
  // Line1,
  Description,
  InfoRow2,
  Column1,
  Column2,
  Column3,
  FirstSlide,
  SecondSlide,
  ThirdSlide,
  InfoRow,
  InfoColumn,
  InfoColumnR,
  TextWrapper,
  ImgWrapper,
  DescriptionL,
  Img,
  //LineBorder,
  First,
  Second,
} from "./HomeSectionNewElements";
import "./HomeSectionNew.css";

function HomeSectionNew({
  title1,
  title2,
  title3,
  headline,
  description1,
  description2,
  description3,
  subheadline,
  imgStart,
  start,
}) {

  const [firstImg, setFirstImg] = useState(true);
  const [secondImg, setSecondImg] = useState(false);
  const [thirdImg, setThirdImg] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 970px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 970px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const switchFirstImg = (event) => {
    setFirstImg(true);
    setSecondImg(false);
    setThirdImg(false);
  };

  const switchSecondImg = (event) => {
    setFirstImg(false);
    setSecondImg(true);
    setThirdImg(false);
  };

  const switchThirdImg = (event) => {
    setFirstImg(false);
    setSecondImg(false);
    setThirdImg(true);
  };

  return (
    <div>
      <InfoContainer>
        <Container>
          <Head>{headline}</Head>
          <SubHead>{subheadline}</SubHead>
          {matches ? (
            <>
              <InfoRow2>
                <Column1>
                  <FirstSlide>
                    <Img src="images/frame1.svg" alt="frame1" />
                    <Title>{title1}</Title>
                    <Description>{description1}</Description>
                  </FirstSlide>
                </Column1>
                <Column2>
                  <SecondSlide>
                    <Img src="images/frame2.svg" alt="frame2" width="70%" />
                    <Title>{title2}</Title>
                    <Description>{description2}</Description>
                  </SecondSlide>
                </Column2>
                <Column3>
                  <ThirdSlide>
                    <Img src="images/frame3.png" alt="frame3" width="70%" />
                    <Title>{title3}</Title>
                    <Description>{description3}</Description>
                  </ThirdSlide>
                </Column3>
              </InfoRow2>
            </>
          ) : (
            <>
              <InfoRow imgStart={imgStart}>
                <InfoColumnR>
                  {/* <LineBorder>
                 <LineWrap>
                        <Line1
                          style={{
                            height: "8px",
                            width: "33%",
                            borderRadius: "16px",
                          }}
                        />

                      </LineWrap>
                 </LineBorder> */}
                  <TextWrapper>
                    {firstImg && (
                      <>
                        <First>
                          <Title onClick={switchFirstImg}>{title1}</Title>
                          <Description>{description1}</Description>
                        </First>
                        <Second>
                          <Title onClick={switchSecondImg}>{title2}</Title>
                          <Description>{description2}</Description>
                        </Second>
                        <Second>
                          <Title onClick={switchThirdImg}>{title3}</Title>
                          <DescriptionL>{description3}</DescriptionL>
                        </Second>
                      </>
                    )}
                    {secondImg && (
                      <>
                        <Second>
                          <Title onClick={switchFirstImg}>{title1}</Title>
                          <Description>{description1}</Description>
                        </Second>
                        <First>
                          <Title onClick={switchSecondImg}>{title2}</Title>
                          <Description>{description2}</Description>
                        </First>
                        <Second>
                          <Title onClick={switchThirdImg}>{title3}</Title>
                          <DescriptionL>{description3}</DescriptionL>
                        </Second>
                      </>
                    )}
                    {thirdImg && (
                      <>
                        <Second>
                          <Title onClick={switchFirstImg}>{title1}</Title>
                          <Description>{description1}</Description>
                        </Second>
                        <Second>
                          <Title onClick={switchSecondImg}>{title2}</Title>
                          <Description>{description2}</Description>
                        </Second>
                        <First>
                          <Title onClick={switchThirdImg}>{title3}</Title>
                          <DescriptionL>{description3}</DescriptionL>
                        </First>
                      </>
                    )}
                  </TextWrapper>
                </InfoColumnR>
                <InfoColumn>
                  <ImgWrapper start={start}>
                    {firstImg && <img src="images/frame1.svg" alt="frame1" />}
                    {secondImg && <img src="images/frame2.svg" alt="frame2" />}
                    {thirdImg && <img src="images/frame3.png" alt="frame3" />}
                  </ImgWrapper>
                </InfoColumn>
              </InfoRow>
            </>
          )}
        </Container>
      </InfoContainer>
    </div>
  );
}

export default HomeSectionNew;
