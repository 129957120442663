import React from "react";
import { Link } from "react-router-dom";
import { Container, Button22, Button44, ButtonGrey } from "../../globalStyles";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  Heading,
  ImgWrapper,
  Img,
  BtnWrap,
  AndriodImg,
  Card,
} from "./HomeSection8Elements";

const linkStyle = {
  textDecoration: "none",
};

function HomeSection8({ lightBg, imgStart, start }) {
  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <Card>
            <InfoRow imgStart={imgStart}>
              <InfoColumn>
                <TextWrapper>
                  <Heading>Start accepting payments in 3 minutes</Heading>
                  <BtnWrap>
                    <Link
                      target="_blank"
                      to={"//app.deempay.com/registration"}
                      style={linkStyle}
                    >
                      <Button44>Create free account</Button44>
                    </Link>
                    <Link
                      target="_blank"
                      to={
                        "//play.google.com/store/apps/details?id=com.deempay.app"
                      }
                      style={linkStyle}
                    >
                      <Button22>
                        <AndriodImg>
                          <img src="images/google-play.svg" alt="App" />
                        </AndriodImg>
                        Get Android App
                      </Button22>
                    </Link>
                    <Link
                      target="_blank"
                      to="//apps.apple.com/ng/app/deempay/id1660927213" 
                      style={linkStyle}
                    >
                    <ButtonGrey>
                      <AndriodImg>
                          <img src="images/ios.svg" alt="App" />
                        </AndriodImg>
                        Get on iOS
                      </ButtonGrey>
                      </Link>
                  </BtnWrap>
                </TextWrapper>
              </InfoColumn>
              <InfoColumn>
                <ImgWrapper start={start}>
                  <Img src="images/Appss.png" alt="Apps-group" />
                </ImgWrapper>
              </InfoColumn>
            </InfoRow>
          </Card>
        </Container>
      </InfoSec>
    </>
  );
}

export default HomeSection8;
