export const homeObjOne = {
  lightBg: true,
  topLine: "",
  headline: "Fast and Modern Payment Collections For your Business.",
  description:
    "A suite of modern tools to manage and grow your business. Accept in-store or in-person payments faster using bank transfer or QR code.",
  buttonLabel: "Create free account",
  buttonLabel2: "Get Android App",
  buttonLabel3: "Get on iOS",
  imgStart: "",
  imgHome: "https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/Frame%20159%20(5).png?alt=media&token=e59532a6-78f2-4ed8-aaf4-05f43002ac25",
  alt: "homeimage",
  img2: "images/rectangle.svg",
  alt2: "rectangle",
  start: "",
};

export const homeObjTwo = {
  lightBg: true,
  topLine: "Cashless, Contactless & Cardless",
  headline: "Fast, Secure and Safe Payment Experience.",
  description:
    "Give your customers the convenience to pay you from their smartphone. It keeps you, your customers and staff safe from Covid-19.",

  imgStart: "",
  img: "https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/Mask%20Group%20(6).png?alt=media&token=334fcc2d-0cf5-4bc7-98ab-2d3b18abdf03",
  alt: "homeimage2",
  start: "",
  img4: "images/web.svg",
  alt4: "homeimage",
  description1: "Trusted by businesses like",
};

export const homeObjThree = {
  description1:
    "With your DeemPay NQR code, your customers can easily pay you by just scanning your QR code using any mobile bank app in Nigeria.",
  description2:
    "With your DeemPay virtual bank account, your customers can now pay you easily using transfer. Both you and your cashiers get instant payment notifications on the mobile or web app.",
  headline1: "Multiple payment methods",
  headline2:
    "Accept mutiple cashless and contactless payment methods for your business",
  stepone: "images/qr-payment.svg",
  steptwo: "images/request-payment.svg",
  title1: "QR Code",
  title2: "Bank Transfer",
};

export const homeObjFour = {
  description1:
    "Customers scan the DeemPay NQR code presented by the merchant to pay using any mobile bank app. The merchant instantly receives settlement to his/her bank account.",
  description2:
    "Customer make transfer to the DeemPay virtual bank account presented by the merchant to pay using USSD or mobile bank app. The merchant receives settlement to his/her bank account every end of the day.",
  headline: "How to accept payments",
  subheadline: "Start accepting payments",
  title1: "With QR Code",
  title2:
    "With Bank Transfer",
  step1: "Step 1",
  step2: "Step 2",
  step3: "Step 3",
  stepone: "images/accept-payment.svg",
  steptwo: "images/request-payment.svg",
  stepthree: "images/request-payment.svg",
  content1: "Customer scans the QR code with any bank app.",
  content2: "Customer inputs the amount, PIN and pay.",
  content3: "Payment successful. Merchant and his cashier receive instant notification.",
  content4: "Customer enters the bank account using USSD or mobile bank app.",
  content5: "Customer inputs the amount, select bank and pay.",
  content6: "Payment successful. Merchant and his cashiers receive instant notification.",
};

export const homeObjNew = {
  lightBg: true,
  start: "",
  imgStart: "",
  description1:
    "DeemPay enables business owners to accept bank transfer and QR code as your payment option for ease of doing business.",
  description2:
    "With the DeemPay mobile or web app, your cashier can confirm all payments made at their points without contacting the business owner or the accountant.",
  description3:
    "See all cashier transaction history on the dashboard.",
  headline: "How DeemPay helps your business",
  subheadline: "With DeemPay, you don't only get tools to accept payment, but get tools to manage and grow your business.",
  title1: "Accept Payment",
  title2: "Retail Sub-account",
  title3: "Transaction Reporting",
  image1: "images/accept-payment.svg",
  image2: "images/request-payment.svg",
  image3: "images/request-payment.svg",
};

export const homeObjFive = {
  headline: "Clear pricing structure, zero hidden costs",
  headline2: "Why use Deempay?",
  description2:
    "Our many starter benefits make it a no brainer. Start small, Scale quickly.",
  description:
    "Our transactional charges are among the lowest in the country, with a pay as you go model, we only make money when you sell.",
  fee: "free",
  buttonLabel: "Learn more",
  merchant: "Dashboard App",
  webApp: "DeemPay App",
  bank: "Bank Transfer",
  qr: "QR Code",
  img11: "images/free.svg",
  img12: "images/0.1.svg",
  img13: "images/bank.svg",
  alt11: "free",
  img1: "images/fast-payment.svg",
  alt1: "fast-payment",
  img2: "images/instant-payment.svg",
  alt2: "instant-payment",
  img3: "images/low-charges.svg",
  alt3: "low-charges",
  img4: "images/no-charge.svg",
  alt4: "transactions",
};

export const homeObjSix = {
  headline: "Maximum Security Assured",
  subHead: "Bank Grade Encryption :",
  subHead2: "PCI DSS and PA DSS :",
  description: "At DeemPay we take security very seriously.",
  description2:
    "All your transactions and data are highly encrypted with the bank-grade encryption standard.",
  description3: "All our systems and APPs are designed and developed following the best payment standards which is the payment card industry data security (PCIDSS).",
  imgStart: "start",
  img: "images/security.svg",
  alt: "security",
};

export const homeObjSeven = {
  headline: "Our Partners",
  headline2:
    "We've partnered with experts in the industry to deliver lightning fast & secure payment infrastructure.",
  tence: "images/tenece-logo.svg",
  alt4: "tenece-logo",
  paythru: "images/paythru-logo.png",
  alt2: "paythru-logo",
  genesys: "images/genesys-logo.png",
  alt: "genesys-logo",
};
