import styled from "styled-components";

export const InfoContainer = styled.div`
  padding: 90px 0;
  background: #ffffff;
  @media screen and (max-width: 768px) {
    margin-top: -9rem;
  }
  @media screen and (max-width: 480px) {
    padding: 40px 0;
    margin-top: 0rem;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
  margin-top: 39px;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const InfoColumnR = styled.div`
  margin-top: 39px;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 600px;
  display: flex;
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
  @media screen and (max-width: 480px) {
    margin-top: -3rem;
  }
`;

export const Head = styled.h1`
  display: flex;
  text-align: start;
  align-items: start;
  color: #0d0d0d;
  font-size: 32px;
  font-weight: 700;
  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`;

export const SubHead = styled.h2`
  display: flex;
  text-align: start;
  align-items: start;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  line-height: 160%;
  font-size: 18px;
  max-width: 595px;
`;

export const Img = styled.img`
  position: relative;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  height: auto;
  @media screen and (max-width: 970px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 20px;
    margin-bottom: 15px;
  }
`;

export const First = styled.div`
  border-left: 6px solid #1a71ff;
  height: 100%;
  padding-left: 42px;
`;

export const Second = styled.div`
  border-left: 6px solid white;
  height: 100%;
  padding-left: 42px;
`;

export const Title = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  color: #000000;
  margin-bottom: 16px;
  cursor: pointer;
  @media screen and (max-width: 970px) {
    margin-top: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  margin-bottom: 10px;
  }
`;

export const LineBorder = styled.div`
  position: relative;
`;

export const LineWrap = styled.div`
  transform: rotate(90deg);
  background: #f8f8f8;
  border-radius: 16px;
`;

export const Line1 = styled.div`
  background: #1a71ff;
`;

export const Description = styled.div`
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  max-width: 432px;
  margin-bottom: 100px;
  color: rgba(0, 0, 0, 0.7);
  @media screen and (max-width: 970px) {
    margin-top: 5px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 500px) {
    max-width: 100%;
    margin-top: 5px;
  }
`;

export const DescriptionL = styled.div`
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  max-width: 432px;
  color: rgba(0, 0, 0, 0.7);
  @media screen and (max-width: 970px) {
    margin-top: 16px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 500px) {
    max-width: 80%;
  }
`;

export const InfoRow2 = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: "col1 col2 col3";
  @media screen and (max-width: 970px) {
    grid-template-areas: "col1" "col2" "col3";
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const Column3 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col3;
`;

export const FirstSlide = styled.div`
  display: grid;
  @media screen and (max-width: 970px) {
    justify-content: center;
    align-item: center;
  }
`;

export const SecondSlide = styled.div`
  display: grid;
  @media screen and (max-width: 970px) {
    justify-content: center;
    align-item: center;
  }
`;

export const ThirdSlide = styled.div`
  display: grid;
  @media screen and (max-width: 970px) {
    justify-content: center;
    align-item: center;
  }
`;
