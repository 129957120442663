import React, { useState } from "react";
import "./Navbar.css";
import { Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavBtn,
  NavLinks,
  NavBtnLink,
  NavLink,
} from "./NavbarElements";

const linksStyle = {
  textDecoration: "none",
};

const linkStyle = {
  textDecoration: "none",
  color: "#000000",
};

const solutions = (
  <Menu className="top-menu">
    <div className="img-dropdown">
      <img
        src="images/solutions.svg"
        alt="solution-icon"
        className="pad-img1"
        width="23%"
        height="auto"
      />
    </div>
    <Menu.Item className="mo">
      <Link to="/deempay-app" className="pad3" style={linksStyle}>
        <span className="">DeemPay App</span>
      </Link>
    </Menu.Item>
    <hr className="dropdown-horizontal-line2"></hr>
    <Menu.Item className="mo4">
      <Link to="/deempay-dashboard" className="pad3" style={linksStyle}>
        DeemPay Dashboard
      </Link>
    </Menu.Item>
  </Menu>
);

const help = (
  <Menu className="top-menu">
    <div className="img-dropdown">
      <img
        src="images/help.svg"
        alt="help-icon"
        className="pad-img1"
        width="23%"
        height="auto"
      />
    </div>
    <Menu.Item className="mo4">
      <Link to="/faq" className="pad" style={linksStyle}>
        FAQ
      </Link>
    </Menu.Item>
    <hr className="dropdown-horizontal-line"></hr>
    <Menu.Item className="mo4">
      <Link to="/supportcenter" className="pad" style={linksStyle}>
        Support Center
      </Link>
    </Menu.Item>
    <hr className="dropdown-horizontal-line"></hr>
    <Menu.Item className="mo4">
      <Link to="/blog" className="pad" style={linksStyle}>
        Blog
      </Link>
    </Menu.Item>
  </Menu>
);

const company = (
  <Menu className="top-menu">
    <div className="img-dropdown">
      <img
        src="images/company.svg"
        alt="company-icon"
        className="pad-img1"
        width="23%"
        height="auto"
      />
    </div>

    <Menu.Item className="mo">
      <Link to="/about" className="pad2" style={linksStyle}>
        About
      </Link>
    </Menu.Item>
    <hr className="dropdown-horizontal-line2"></hr>
    <Menu.Item className="mo4">
      <Link to="/careers" className="pad2" style={linksStyle}>
        {" "}
        Careers{" "}
      </Link>
    </Menu.Item>
  </Menu>
);

function Navbar({ toggle }) {
  const [navbr, setNavbr] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbr(true);
    } else {
      setNavbr(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav className={navbr ? "navbr active" : "navbr"}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <img src={"../../images/deempay-logo.svg"} alt="logo" />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            {
              <span>
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.8"
                  stroke="currentColor"
                  width="38"
                  height="35"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </span>
            }
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks style={linkStyle}>
                <Dropdown overlay={solutions}>
                  <div className="ant-dropdown-link nav-links">
                    Solutions
                    {
                      <span className="drop2">
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="4"
                          stroke="currentColor"
                          //class="w-3 h-3"

                          width="18"
                          height="11"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    }
                  </div>
                </Dropdown>
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks style={linkStyle}>
                <Link to="/pricing" style={linkStyle}>
                  Pricing
                </Link>
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks style={linkStyle}>
                <Dropdown overlay={help}>
                  <div className="ant-dropdown-link nav-links">
                    Help
                    {
                      <span className="drop2">
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="4"
                          stroke="currentColor"
                          //class="w-3 h-3"

                          width="18"
                          height="11"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                      // <img
                      //   src="images/drop.svg"
                      //   alt="icon"
                      //   className="drop-down"
                      // />
                    }
                  </div>
                </Dropdown>
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks style={linkStyle}>
                <Dropdown overlay={company}>
                  <div className="ant-dropdown-link nav-links">
                    Company
                    {
                      <span className="drop2">
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="4"
                          stroke="currentColor"
                          //class="w-3 h-3"

                          width="18"
                          height="11"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    }
                  </div>
                </Dropdown>
              </NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavLink
              target="_blank"
              to={"//app.deempay.com/login"}
              style={linkStyle}
            >
              Log In
            </NavLink>
            <NavBtnLink target="_blank" to={"//app.deempay.com/registration"}>
              Get Started
            </NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
