import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Button2, Button7 } from "../../globalStyles";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  BtnWrap,
  AndriodImg,
  RecImg,
  BtnFree,
} from "./HomeSectionElements";
import "./HomeSection.css";

const linkStyle = {
  textDecoration: "none",
};

function HomeSection({
  lightBg,
  description,
  buttonLabel,
  buttonLabel2,
  buttonLabel3,
  alt,
  img2,
  alt2,
  imgStart,
  start,
  imgHome,
}) {
  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                <Heading>
                  Fast and Modern <br />
                  Payment Collections
                  <br />
                  For your Business.
                </Heading>
                <Subtitle>{description}</Subtitle>
                <BtnFree>
                  <Link
                    target="_blank"
                    to={"//app.deempay.com/registration"}
                    style={linkStyle}
                  >
                    <Button7>{buttonLabel}</Button7>
                  </Link>
                </BtnFree>
                <BtnWrap>
                  <Link
                    target="_blank"
                    to={
                      "//play.google.com/store/apps/details?id=com.deempay.app"
                    }
                    style={linkStyle}
                  >
                    <Button>
                      <AndriodImg>
                        <img src="images/google-play.svg" alt="App" />
                      </AndriodImg>
                      {buttonLabel2}
                    </Button>
                  </Link>
                  <Link 
                   target="_blank"
                   to="//apps.apple.com/ng/app/deempay/id1660927213"
                   style={linkStyle}>
                  <Button2>
                    <AndriodImg>
                      <img src="images/ios.svg" alt="App" />
                    </AndriodImg>
                    {buttonLabel3}
                  </Button2>
                  </Link>
                </BtnWrap>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={start}>
                <RecImg src={img2} alt={alt2} />
                <Img
                  src={imgHome} alt={alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
}

export default HomeSection;
