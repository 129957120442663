import React from "react";
import { Container } from "../../globalStyles";

import {
  InfoContainer,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  ImgWrap,
  Heading,
  Subtitle,
  Img,
} from "./HomeSection3Elements";
import "./HomeSection3.css";
function HomeSection3({
  stepone,
  steptwo,
  one,
  two,
  title1,
  title2,
  headline1,
  description1,
  description2,
  headline2,
}) {
  return (
    <div>
    <InfoContainer>
      <h1 className="how-works">{headline1}</h1>
      <h2 className="payment-steps">{headline2}</h2>
      <Container>
        <InfoRow>
          <Column1>
            <TextWrapper>
              <Img src={stepone} alt={one}  className="merchant-img" />
              <Heading>{title1}</Heading>
              <Subtitle>
                <p className="description">{description1}</p>
              </Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={steptwo} alt={two}  className="merchant-img" />
              <Heading>{title2}</Heading>
              <Subtitle>
                <p className="description">{description2}</p>
              </Subtitle>
            </ImgWrap>
          </Column2>
          </InfoRow>
      </Container>
    </InfoContainer>
</div>
  );
}

export default HomeSection3;
