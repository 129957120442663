import styled from "styled-components";

export const InfoSec = styled.div`
  color: #fff;
  padding: 160px 0;
  background: ${({ lightBg }) => (lightBg ? "#ffffff" : "#fafafa")};
  @media screen and (max-width: 480px) {
    padding: 95px 0 80px 0;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 600px;
  position: relative;
  display: flex;
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  position: relative;
  z-index: 2;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Heading = styled.h1`
  max-width: 530px;
  width: 100%;
  line-height: 56px;
  font-size: 48px;
  font-weight: 600;
  @media screen and (max-width: 480px) {
    line-height: 1.1;
    font-size: 38px;
  }
  @media screen and (max-width: 390px) {
    font-size: 38px;
  }
  @media screen and (max-width: 375px) {
    font-size: 34px;
  }
  @media screen and (max-width: 360px) {
    font-size: 32px;
  }
  @media screen and (max-width: 1000px) {
    max-width: 100%;
  }
`;

export const Head2 = styled.div`
  margin-bottom: 24px;
  max-width: 530px;
  position: relative;
  h2 {
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    margin-top: -60px;
    color: #0d0d0d;
    @media screen and (max-width: 480px) {
      font-size: 40px;
      text-align: center;
      margin-top: -65px;
    }
    @media screen and (max-width: 360px) {
      font-size: 38px;
      text-align: left;
      margin-top: -50px;
    }
  }
`;

export const BtnFree = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: flex-start;
  @media screen and (max-width: 480px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }
  @media screen and (max-width: 375px) {
    margin-bottom: 0px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 375px) {
    display: grid;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const Subtitle = styled.p`
  max-width: 490px;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const AndriodImg = styled.span`
  padding-right: 10px;
`;

export const RecImg = styled.img`
  z-index: 1;
  position: absolute;
  max-width: 555px;
  padding-right: 8.5rem;
  margin-top: -1.2rem;
  @media screen and (max-width: 991px) {
    padding-right: 3rem;
    margin-top: -2rem;
  }
  @media screen and (max-width: 768px) {
    padding-right: 4rem;
    margin-top: -2rem;
    max-width: 400px;
  }
  @media screen and (max-width: 500px) {
    padding-right: 3rem;
    margin-top: -2rem;
    max-width: 205px;
  }
  @media screen and (max-width: 480px) {
    padding-right: 4rem;
    margin-top: -1rem;
    max-width: 300px;
  }
  @media screen and (max-width: 375px) {
    padding-right: 4.6rem;
    margin-top: -0.6rem;
    max-width: 250px;
  }
  @media screen and (max-width: 280px) {
    padding-right: 2rem;
    margin-top: -1rem;
    max-width: 200px;
  }
`;
