import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import "./Sidebar.css";
import {
  SidebarContainer,
  SidebarBtn,
  Icon,
  SidebarWrapper,
  SidebarMenu,
  SidebarRoute,
  SidebarRoute1,
  SidebarLink,
  SideBtnWrap,
  SidebarLogo,
  SidebarLink2,
} from "./SidebarElements";

const linksStyle = {
  textDecoration: "none",
};

const linkStyle = {
  textDecoration: "none",
  color: "#000000",
};

const linkyStyle = {
  textDecoration: "none",
  color: "#ffffff",
};

const Sidebar = ({ isOpen, toggle }) => {
  const solutions = (
    <Menu className="bar-menu">
      <div className="img-dropdown">
        <img
          src="images/solutions.svg"
          alt="solution-icon"
          className="pad-img1"
          width="23%"
          height="auto"
        />
      </div>
      <Menu.Item className="mo">
        <Link
          to="/deempay-app"
          className="pad3"
          onClick={toggle}
          style={linksStyle}
        >
          DeemPay App
        </Link>
      </Menu.Item>
      <hr className="dropdown-horizontal-line2"></hr>
      <Menu.Item className="mo4">
        <Link
          to="/deempay-dashboard"
          className="pad3"
          onClick={toggle}
          style={linksStyle}
        >
          DeemPay Dashboard
        </Link>
      </Menu.Item>
    </Menu>
  );

  const help = (
    <Menu className="bar1-menu">
      <div className="img-dropdown">
        <img
          src="images/help.svg"
          alt="help-icon"
          className="pad-img1"
          width="23%"
          height="auto"
        />
      </div>
      <Menu.Item className="mo3">
        <Link to="/faq" className="pad" onClick={toggle} style={linksStyle}>
          FAQ
        </Link>
      </Menu.Item>
      <hr className="dropdown-horizontal-line"></hr>
      <Menu.Item className="mo3">
        <Link
          to="/supportcenter"
          className="pad"
          onClick={toggle}
          style={linksStyle}
        >
          Support Center
        </Link>
      </Menu.Item>
      <hr className="dropdown-horizontal-line"></hr>
      <Menu.Item className="mo3">
        <Link to="/blog" className="pad" onClick={toggle} style={linksStyle}>
          Blog
        </Link>
      </Menu.Item>
    </Menu>
  );

  const company = (
    <Menu className="bar-menu">
      <div className="img-dropdown">
        <img
          src="images/company.svg"
          alt="company-icon"
          className="pad-img1"
          width="23%"
          height="auto"
        />
      </div>

      <Menu.Item className="mo">
        <Link to="/about" className="pad2" onClick={toggle} style={linksStyle}>
          About
        </Link>
      </Menu.Item>
      <hr className="dropdown-horizontal-line2"></hr>
      <Menu.Item className="mo4">
        <Link
          to="/careers"
          className="pad2"
          onClick={toggle}
          style={linksStyle}
        >
          Careers
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <SidebarLogo>
        <Link onClick={toggle} to="/">
          <img src={"../../images/deempay-logo.svg"} alt="logo" />
        </Link>
      </SidebarLogo>
      <Icon onClick={toggle}>
        {
          <span>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.8"
              stroke="currentColor"
              width="38"
              height="35"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </span>
        }
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink style={linkStyle}>
            <Dropdown overlay={solutions}>
              <div className="ant-dropdown-link nav-links">
                Solutions
                {
                  <span className="drop2">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="4"
                      stroke="currentColor"
                      width="18"
                      height="11"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                }
              </div>
            </Dropdown>
          </SidebarLink>
          <SidebarLink style={linkStyle}>
            <Link to="/pricing" onClick={toggle} style={linkStyle}>
              Pricing
            </Link>
          </SidebarLink>
          <SidebarLink style={linkStyle}>
            <Dropdown overlay={help}>
              <div className="ant-dropdown-link nav-links">
                Help
                {
                  <span className="drop2">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="4"
                      stroke="currentColor"
                      //class="w-3 h-3"

                      width="18"
                      height="11"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                }
              </div>
            </Dropdown>
          </SidebarLink>
          <SidebarLink style={linkStyle}>
            <Dropdown overlay={company}>
              <div className="ant-dropdown-link nav-links">
                Company
                {
                  <span className="drop2">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="4"
                      stroke="currentColor"
                      //class="w-3 h-3"

                      width="18"
                      height="11"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                }
              </div>
            </Dropdown>
          </SidebarLink>
          <SidebarLink2 style={linkStyle}>
            <SidebarRoute
              target="_blank"
              to={"//app.deempay.com/login"}
              style={linkStyle}
              onClick={toggle}
            >
              Log In
            </SidebarRoute>
          </SidebarLink2>
          <SideBtnWrap>
            <SidebarBtn>
              <SidebarRoute1
                target="_blank"
                to={"//app.deempay.com/registration"}
                style={linkyStyle}
              >
                Get Started
              </SidebarRoute1>
            </SidebarBtn>
          </SideBtnWrap>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
