import React, { useState, useEffect, useRef } from "react";
import { Container } from "../../globalStyles";
import { Carousel } from "antd";
import {
  InfoContainer,
  Head,
  SubHead,
  QrWrap,
  Title,
  TitleWrap,
  Title2,
  LineWrap,
  Line1,
  Line2,
  Description,
  SlideWrap,
  FirstSlide,
  SecondSlide,
  ThirdSlide,
  ContentOne,
  Step1,
  Button,
  LineWrap2,
  Title3,
  Title4,
} from "./HomeSection4Elements";
import "./HomeSection4.css";
function HomeSection4({
  step1,
  step2,
  step3,
  content1,
  content2,
  content3,
  content4,
  content5,
  content6,
  title1,
  title2,
  headline,
  description1,
  description2,
  subheadline,
}) {
  const ref = useRef();
  const goTo = (slide) => {
    ref.current.goTo(slide, false);
  };

  const [showQR, setShowQR] = useState(true);
  const [showBank, setShowBank] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [isSecond, setIsSecond] = useState(false);
  const [isThird, setIsThird] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 970px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 970px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const switchQR = (event) => {
    setShowQR(true);
    setShowBank(false);
  };

  const switchBank = (event) => {
    setShowQR(false);
    setShowBank(true);
  };

  const onChange = (currentSlide) => {
    if (currentSlide === 0) {
      setIsFirst(true);
      setIsSecond(false);
      setIsThird(false);
    } else if (currentSlide === 1) {
      setIsFirst(false);
      setIsSecond(true);
      setIsThird(false);
    } else if (currentSlide === 2) {
      setIsFirst(false);
      setIsSecond(false);
      setIsThird(true);
    }
  };

  const btnStyle = {
    width: "10px",
    height: "10px",
    marginRight: "16px",
    marginTop: "20px",
    background: "#1a71ff",
    border: "none",
    borderRadius: "50px",
  };

  const actStyle = {
    width: "10px",
    height: "10px",
    marginRight: "16px",
    marginTop: "20px",
    background: "#f1f1f1",
    border: "none",
    borderRadius: "50px",
  };

  return (
    <div>
      <InfoContainer>
        <Container>
          <Head>{headline}</Head>
          <SubHead>{subheadline}</SubHead>
          {matches ? (
            <>
              {showQR && (
                <>
                  <TitleWrap>
                    <Title onClick={switchQR}>{title1}</Title>
                    <Title2 onClick={switchBank}>{title2}</Title2>
                  </TitleWrap>
                  <LineWrap>
                    <Line1 style={{ height: "8px", width: "50%" }} />
                  </LineWrap>
                  <Description>{description1}</Description>
                  <Carousel
                    ref={ref}
                    afterChange={onChange}
                    effect="fade"
                    dots={false}
                  >
                    <FirstSlide>
                      <Button>
                        <Step1>{step1}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/scan_1.png?alt=media&token=6413c7f6-1547-4fb9-833d-0b1a739e36e0" alt="scan-Pay" width="90%" height="auto"/>
                      <ContentOne>{content1}</ContentOne>
                    </FirstSlide>
                    <SecondSlide>
                      <Button>
                        <Step1>{step2}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/scan_2.png?alt=media&token=58a69fd7-0134-4238-970f-5aea7da0c9bd" alt="scan-Pay" width="90%" height="auto" />

                      <ContentOne>{content2}</ContentOne>
                    </SecondSlide>
                    <ThirdSlide>
                      <Button>
                        <Step1>{step3}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/scan_3.png?alt=media&token=e84bd0df-79b5-4ec0-ae71-343ce1cc12ab" alt="scan-Pay" width="90%" height="auto" />
                      <ContentOne>{content3}</ContentOne>
                    </ThirdSlide>
                  </Carousel>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={() => goTo(0)}
                      style={isFirst ? btnStyle : actStyle}
                    />
                    <button
                      onClick={() => goTo(1)}
                      style={isSecond ? btnStyle : actStyle}
                    />
                    <button
                      onClick={() => goTo(2)}
                      style={isThird ? btnStyle : actStyle}
                    />
                  </div>
                </>
              )}

              {showBank && (
                <>
                  <TitleWrap>
                    <Title3 onClick={switchQR}>{title1}</Title3>
                    <Title4 onClick={switchBank}>{title2}</Title4>
                  </TitleWrap>
                  <LineWrap2>
                    <Line2 style={{ height: "8px", width: "50%" }} />
                  </LineWrap2>
                  <Description>{description2}</Description>
                  <Carousel
                    ref={ref}
                    afterChange={onChange}
                    effect="fade"
                    dots={false}
                  >
                    <FirstSlide>
                      <Button>
                        <Step1>{step1}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/trans_1.png?alt=media&token=a826b3f4-cd8c-40ee-a472-b7c7678519d8" alt="transfer" width="90%" height="auto" />
                      <ContentOne>{content4}</ContentOne>
                    </FirstSlide>
                    <SecondSlide>
                      <Button>
                        <Step1>{step2}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/trans_2.png?alt=media&token=409acee8-651c-4995-b69a-ba29945f8900" alt="transfer" width="90%" height="auto" />
                      <ContentOne>{content5}</ContentOne>
                    </SecondSlide>
                    <ThirdSlide>
                      <Button>
                        <Step1>{step3}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/trans_3.png?alt=media&token=6667426c-6b6f-4995-bfd5-4e738f19f9a3" alt="transfer" width="90%" height="auto" />
                      <ContentOne>{content6}</ContentOne>
                    </ThirdSlide>
                  </Carousel>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={() => goTo(0)}
                      style={isFirst ? btnStyle : actStyle}
                    />
                    <button
                      onClick={() => goTo(1)}
                      style={isSecond ? btnStyle : actStyle}
                    />
                    <button
                      onClick={() => goTo(2)}
                      style={isThird ? btnStyle : actStyle}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {showQR && (
                <QrWrap>
                  <TitleWrap>
                    <Title onClick={switchQR}>{title1}</Title>
                    <Title2 onClick={switchBank}>{title2}</Title2>
                  </TitleWrap>
                  <LineWrap>
                    <Line1 style={{ height: "8px", width: "50%" }} />
                  </LineWrap>
                  <Description>{description1}</Description>
                  <SlideWrap>
                    <FirstSlide>
                      <Button>
                        <Step1>{step1}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/scan_1.png?alt=media&token=6413c7f6-1547-4fb9-833d-0b1a739e36e0" alt="scan-Pay" width="90%" height="auto"/>
                      <ContentOne>{content1}</ContentOne>
                    </FirstSlide>
                    <SecondSlide>
                      <Button>
                        <Step1>{step2}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/scan_2.png?alt=media&token=58a69fd7-0134-4238-970f-5aea7da0c9bd" alt="scan-Pay" width="90%" height="auto" />
                      <ContentOne>{content2}</ContentOne>
                    </SecondSlide>
                    <ThirdSlide>
                      <Button>
                        <Step1>{step3}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/scan_3.png?alt=media&token=e84bd0df-79b5-4ec0-ae71-343ce1cc12ab" alt="scan-Pay" width="90%" height="auto" />
                      <ContentOne>{content3}</ContentOne>
                    </ThirdSlide>
                  </SlideWrap>
                </QrWrap>
              )}

              {showBank && (
                <QrWrap>
                  <TitleWrap>
                    <Title3 onClick={switchQR}>{title1}</Title3>
                    <Title4 onClick={switchBank}>{title2}</Title4>
                  </TitleWrap>
                  <LineWrap2>
                    <Line2 style={{ height: "8px", width: "50%" }} />
                  </LineWrap2>
                  <Description>{description2}</Description>
                  <SlideWrap>
                    <FirstSlide>
                      <Button>
                        <Step1>{step1}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/trans_1.png?alt=media&token=a826b3f4-cd8c-40ee-a472-b7c7678519d8" alt="transfer" width="90%" height="auto" />
                      <ContentOne>{content4}</ContentOne>
                    </FirstSlide>
                    <SecondSlide>
                      <Button>
                        <Step1>{step2}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/trans_2.png?alt=media&token=409acee8-651c-4995-b69a-ba29945f8900" alt="transfer" width="90%" height="auto" />
                      <ContentOne>{content5}</ContentOne>
                    </SecondSlide>
                    <ThirdSlide>
                      <Button>
                        <Step1>{step3}</Step1>
                      </Button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/deempay-d9d45.appspot.com/o/trans_3.png?alt=media&token=6667426c-6b6f-4995-bfd5-4e738f19f9a3" alt="transfer" width="90%" height="auto" />
                      <ContentOne>{content6}</ContentOne>
                    </ThirdSlide>
                  </SlideWrap>
                </QrWrap>
              )}
            </>
          )}
        </Container>
      </InfoContainer>
    </div>
  );
}

export default HomeSection4;
