import styled from "styled-components";

export const InfoContainer = styled.div`
  padding: 90px 0;
  background: #ffffff;
  @media screen and (max-width: 768px) {
    margin-top: -9rem;
  }
  @media screen and (max-width: 480px) {
    padding: 40px 0;
    margin-top: -3.5rem;
  }
`;

export const Head = styled.h1`
  display: flex;
  text-align: start;
  align-items: start;
  color: #000000;
  font-size: 32px;
  font-weight: 500;
  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`;

export const SubHead = styled.h2`
  display: flex;
  text-align: start;
  align-items: start;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  line-height: 16px;
  font-size: 16px;
`;

export const QrWrap = styled.div`
  display: grid;
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 22px;
`;

export const Title = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  color: #1a1a1a;
  cursor: pointer;
  &: hover {
    color: #1a71ff;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const Title2 = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  color: #8c8c8c;
  cursor: pointer;
  padding-right: 420px;
  &: hover {
    color: #1a71ff;
  }
  @media screen and (max-width: 1290px) {
    padding-right: 320px;
  }
  @media screen and (max-width: 1085px) {
    padding-right: 220px;
  }
  @media screen and (max-width: 960px) {
    padding-right: 0px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const Title3 = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  color: #8c8c8c;
  cursor: pointer;
  &: hover {
    color: #1a71ff;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const Title4 = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  color: #1a1a1a;
  cursor: pointer;
  padding-right: 420px;
  &: hover {
    color: #1a71ff;
  }
  @media screen and (max-width: 1290px) {
    padding-right: 320px;
  }
  @media screen and (max-width: 1085px) {
    padding-right: 220px;
  }
  @media screen and (max-width: 960px) {
    padding-right: 0px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const LineWrap = styled.div`
  background: #f8f8f8;
  margin-bottom: 23px;
`;

export const LineWrap2 = styled.div`
  background: #1a71ff;
  margin-bottom: 23px;
`;

export const Line1 = styled.div`
  background: #1a71ff;
`;

export const Line2 = styled.div`
  background: #f8f8f8;
`;

export const Description = styled.div`
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  max-width: 100%;
  margin-bottom: 49px;
  color: rgba(0, 0, 0, 0.7);
`;

export const SlideWrap = styled.div`
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
`;

export const FirstSlide = styled.div`
  display: grid;
  @media screen and (max-width: 970px) {
  justify-content: center;
  align-item: center;
  max-width: 600px;
  margin-left: 22%;
 // margin-right: 12%;

  }
  @media screen and (max-width: 760px) {
  justify-content: center;
  align-item: center;
  margin-left: 10%;
  }
  @media screen and (max-width: 480px) {
  justify-content: center;
  align-item: center;
  margin-left: 5%;
  max-width: 100%;

  }
  @media screen and (max-width: 375px) {
  justify-content: center;
  align-item: center;
  margin-left: 4%;
  }
`;

export const SecondSlide = styled.div`
  display: grid;
  @media screen and (max-width: 970px) {
  justify-content: center;
  align-item: center;
  max-width: 600px;
  margin-left: 22%;

  }
  @media screen and (max-width: 760px) {
  justify-content: center;
  align-item: center;
  margin-left: 10%;
  }
  @media screen and (max-width: 480px) {
  justify-content: center;
  align-item: center;
  margin-left: 5%;
  max-width: 100%;

  }
  @media screen and (max-width: 375px) {
  justify-content: center;
  align-item: center;
  margin-left: 4%;
  }
`;

export const ThirdSlide = styled.div`
  display: grid;
  @media screen and (max-width: 970px) {
  justify-content: center;
  align-item: center;
  max-width: 600px;
  margin-left: 22%;
  }
  @media screen and (max-width: 760px) {
  justify-content: center;
  align-item: center;
  margin-left: 10%;
  }
  @media screen and (max-width: 480px) {
  justify-content: center;
  align-item: center;
  margin-left: 5%;
  max-width: 100%;

  }
  @media screen and (max-width: 375px) {
  justify-content: center;
  align-item: center;
  margin-left: 4%;
  }
`;

export const Button = styled.div`
  background: #e5efff;
  border-radius: 4px;
  width: 78px;
  padding: 8px 16px 0px 16px;
  margin-bottom: 16px;
  @media screen and (max-width: 970px) {
    padding: 8px 16px 0.017px 16px;
  }
`;

export const Step1 = styled.h3`
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: #003a99;
`;

export const ContentOne = styled.p`
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  max-width: 293px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 23px;
`;
